import React, { useState, useEffect } from 'react';
import { FaHome, FaFileSignature, FaCog, FaChevronDown, FaUsers, FaBed, FaFile, FaCalendarCheck } from 'react-icons/fa';
import { BiSolidReport } from "react-icons/bi";
import { LuFileSearch } from "react-icons/lu";

import { Link } from "react-router-dom";
import { getDocs, collection, query, where, doc, updateDoc, onSnapshot } from 'firebase/firestore';
import { auth, firestoreDb } from '../config/firebase';

const Sidebar = () => {
    const [isStaffDropdownOpen, setIsStaffDropdownOpen] = useState(false);
    const [isWeeklyDropdownOpen, setIsWeeklyDropdownOpen] = useState(false);
    const [isDispatchDropdownOpen, setIsDispatchDropdownOpen] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSuperUser, setIsSuperUser] = useState(false);
    const [isUser, setIsUser] = useState(false);

    const [isAuthenticated, setIsAuthenticated] = useState(false);  // Track authentication status

    const toggleStaffDropdown = () => setIsStaffDropdownOpen(prevState => !prevState);
    const toggleWeeklyDropdown = () => setIsWeeklyDropdownOpen(!isWeeklyDropdownOpen);
    const toggleDispatchDropdown = () => setIsDispatchDropdownOpen(!isDispatchDropdownOpen);

    const markAsRead = async () => {
        const userEmail = auth.currentUser?.email;
        
        if (!userEmail) {
            console.error('User email is undefined, cannot mark leave as read');
            return;
        }
    
        try {
            const leaveAppsQuery = query(
                collection(firestoreDb, 'leave_applications'),
                where('applicationSubmittedBy', '==', userEmail),
                where('isRead', '==', false)
            );
    
            const snapshot = await getDocs(leaveAppsQuery);
    
            snapshot.forEach(async (docSnapshot) => {
                const docRef = doc(firestoreDb, 'leave_applications', docSnapshot.id);
                await updateDoc(docRef, { isRead: true });
            });
    
        } catch (error) {
            console.error('Error marking leave as read:', error);
        }
    };

    const handleAttendanceClick = () => {
        console.log('Attendance link clicked');
    };

    useEffect(() => {
        const checkAuthStatus = () => {
            if (auth.currentUser) {
                setIsAuthenticated(true);
            }
        };

        checkAuthStatus();

        // Ensure auth.currentUser is available before querying Firestore
        if (!isAuthenticated) return;

        const unsubscribe = onSnapshot(
            query(
                collection(firestoreDb, 'leave_applications'),
                where('applicationSubmittedBy', '==', auth.currentUser?.email)
            ),
            (snapshot) => {
                snapshot.forEach((doc) => {
                    const data = doc.data();
                    if (data.status !== 'Pending' && !data.isRead) {
                    }
                });
            }
        );

        return () => unsubscribe();
    }, [isAuthenticated]);  // This effect only runs when the user is authenticated

    useEffect(() => {
        const fetchUserRole = async () => {
            if (auth.currentUser) {
                try {
                    const userRolesRef = collection(firestoreDb, 'user_roles');
                    const q = query(userRolesRef, where('user_id', '==', auth.currentUser.uid));
                    const querySnapshot = await getDocs(q);
    
                    if (!querySnapshot.empty) {
                        querySnapshot.forEach((doc) => {
                            const userData = doc.data();
                            if (userData.role === 'Admin') {
                                setIsAdmin(true);
                            } else if (userData.role === 'super_admin') {
                                setIsSuperUser(true);
                            } else if (userData.role === 'user') {
                                setIsUser(true);
                            }
                        });
                    } else {
                        console.log('No matching user role found');
                    }
                } catch (error) {
                    console.error("Error fetching user role:", error);
                }
            }
        };

        fetchUserRole();
    }, [isAuthenticated]);  // Ensure the role fetch only happens once authenticated

    useEffect(() => {
        const currentPath = window.location.pathname;
        console.log('Current path:', currentPath);
        if (currentPath === '/staff_attendence') {
            console.log('On Attendance page');
        }
    }, []);

    const scrollbarStyles = {
        scrollbarWidth: 'thin',
        scrollbarColor: '#4A5568 #1A202C',
        WebkitScrollbar: {
            width: '8px',
        },
        WebkitScrollbarTrack: {
            background: '#1A202C',
        },
        WebkitScrollbarThumb: {
            backgroundColor: '#4A5568',
            borderRadius: '4px',
            border: '2px solid #1A202C',
        }
    };

    return (
        <div style={scrollbarStyles} className="relative w-64 bg-gray-900 text-white flex flex-col shadow-lg overflow-y-auto">
            <div className="relative z-10 flex flex-col flex-grow">
                {/* Sidebar Header */}
                <div className="flex items-center justify-center h-16 shadow-md">
                    <h1 className="text-lg font-semibold">YangKhor Private Ltd</h1>
                </div>

                {/* Sidebar Navigation */}
                <nav className="flex-1 p-4 flex flex-col">
                    <ul>
                        <li className="mb-4">
                            <Link to="/dashboard" className="flex items-center p-3 text-gray-300 hover:bg-gray-700 rounded-lg transition-all">
                                <FaHome className="mr-3" /> Home
                            </Link>
                        </li>
                        <li className="mb-4">
                            <Link
                                to="/apply_leave"
                                className="flex items-center p-3 text-gray-300 hover:bg-gray-700 rounded-lg transition-all"
                                onClick={markAsRead} // Mark changes as read when the user clicks
                            >
                                <FaBed className="mr-3" /> Apply For Leave
                            </Link>
                        </li>
                        <li className="mb-4">
                            <Link to="/user_projects" className="flex items-center p-3 text-gray-300 hover:bg-gray-700 rounded-lg transition-all">
                                <FaFileSignature className="mr-3" /> Projects
                            </Link>
                        </li>
                        {isUser && (
                            <li className="mb-4">
                                <Link to="/daily_report" className="flex items-center p-3 text-gray-300 hover:bg-gray-700 rounded-lg transition-all">
                                    <FaCalendarCheck className="mr-3" /> Weekly Report
                                </Link>
                            </li>
                        )}
                        {/* Admin Links (Conditional) */}
                        {isAdmin && (
                            <>
                            <li className="mb-4">
                                    <div className="flex items-center p-3 text-gray-300 hover:bg-gray-700 rounded-lg cursor-pointer transition-all" onClick={toggleWeeklyDropdown}>
                                        <FaCalendarCheck className="mr-3" /> Weekly Report
                                        <span className={`ml-auto transform transition-transform ${isWeeklyDropdownOpen ? 'rotate-180' : ''}`}>
                                            <FaChevronDown />
                                        </span>
                                    </div>
                                    <div className={`transition-all duration-300 overflow-hidden ${isWeeklyDropdownOpen ? 'max-h-40' : 'max-h-0'}`}>
                                        <ul className="ml-6 mt-2">
                                            <li className="mb-4">
                                                <Link to="/daily_report" className="flex items-center p-3 text-gray-300 hover:bg-gray-700 rounded-lg transition-all">
                                                    Submit Weekly Report
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/view_daily_report" className="block p-2 text-gray-300 hover:bg-gray-600 rounded-lg transition-all">View Weekly Reports</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="mb-4">
                                    <Link to="/monthly_report" className="flex items-center p-3 text-gray-300 hover:bg-gray-700 rounded-lg transition-all">
                                        <BiSolidReport className="mr-3" /> Monthly Report
                                    </Link>
                                </li>
                                <li className="mb-4">
                                    <Link to="/staff_attendence_hr" className="flex items-center p-3 text-gray-300 hover:bg-gray-700 rounded-lg transition-all">
                                        <LuFileSearch className="mr-3" /> Leave Applications
                                    </Link>
                                </li>
                                <li className="mb-4">
                                    <div className="flex items-center p-3 text-gray-300 hover:bg-gray-700 rounded-lg cursor-pointer transition-all" onClick={toggleDispatchDropdown}>
                                        <FaFile className="mr-3" /> Dispatch
                                        <span className={`ml-auto transform transition-transform ${isDispatchDropdownOpen ? 'rotate-180' : ''}`}>
                                            <FaChevronDown />
                                        </span>
                                    </div>
                                    <div className={`transition-all duration-300 overflow-hidden ${isDispatchDropdownOpen ? 'max-h-40' : 'max-h-0'}`}>
                                        <ul className="ml-6 mt-2">
                                            <li className="mb-4">
                                                <Link to="/dispatch_form" className="flex items-center p-3 text-gray-300 hover:bg-gray-700 rounded-lg transition-all">
                                                    Dispatch File
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/dispatch_history" className="block p-2 text-gray-300 hover:bg-gray-600 rounded-lg transition-all">Dispatch List</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                {/* Staff Dropdown */}
                                <li className="mb-4">
                                    <div className="flex items-center p-3 text-gray-300 hover:bg-gray-700 rounded-lg cursor-pointer transition-all" onClick={toggleStaffDropdown}>
                                        <FaUsers className="mr-3" /> Staffs
                                        <span className={`ml-auto transform transition-transform ${isStaffDropdownOpen ? 'rotate-180' : ''}`}>
                                            <FaChevronDown />
                                        </span>
                                    </div>
                                    <div className={`transition-all duration-300 overflow-hidden ${isStaffDropdownOpen ? 'max-h-40' : 'max-h-0'}`}>

                                        <ul className="ml-6 mt-2">
                                            <li className="mb-4">
                                                <Link to="/add_staff" className="flex items-center p-3 text-gray-300 hover:bg-gray-700 rounded-lg transition-all">
                                                    Add Staff
                                                </Link>
                                            </li>
                                            <li className="mb-4">
                                                <Link to="/add_user" className="flex items-center p-3 text-gray-300 hover:bg-gray-700 rounded-lg transition-all">
                                                    Add User
                                                </Link>
                                            </li>
                                            <li className="mb-4">
                                                <Link to="/staff_list" className="block p-2 text-gray-300 hover:bg-gray-600 rounded-lg transition-all">Staff List</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </>
                        )}

                        {/* Superuser Links (Conditional) */}
                        {isSuperUser && (
                            <>
                                <li className="mb-4">
                                    <Link 
                                        to="/staff_attendence"
                                        className="flex items-center p-3 text-gray-300 hover:bg-gray-700 rounded-lg transition-all"
                                        onClick={handleAttendanceClick} // Clear the red dot when clicked
                                    >
                                        <FaCog className="mr-3" />
                                        Leave Applications
                                    </Link>
                                </li>

                                {/* Staff Dropdown */}
                                <li className="mb-4">
                                    <div className="flex items-center p-3 text-gray-300 hover:bg-gray-700 rounded-lg cursor-pointer transition-all" onClick={toggleStaffDropdown}>
                                        <FaUsers className="mr-3" /> Staffs
                                        <span className={`ml-auto transform transition-transform ${isStaffDropdownOpen ? 'rotate-180' : ''}`}>
                                            <FaChevronDown />
                                        </span>
                                    </div>
                                    <div className={`transition-all duration-300 overflow-hidden relative z-10 ${isStaffDropdownOpen ? 'max-h-40' : 'max-h-0'}`}>
                                        <ul className="ml-6 mt-2">
                                            <li className="mb-4">
                                                <Link to="/add_staff" className="flex items-center p-3 text-gray-300 hover:bg-gray-700 rounded-lg transition-all">
                                                    Add Staff
                                                </Link>
                                            </li>
                                            <li className="mb-4">
                                                <Link to="/add_staff" className="flex items-center p-3 text-gray-300 hover:bg-gray-700 rounded-lg transition-all">
                                                    Add User
                                                </Link>
                                            </li>
                                            <li className="mb-4">
                                                <Link to="/staff_list" className="block p-2 text-gray-300 hover:bg-gray-600 rounded-lg transition-all">Staff List</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </>
                        )}
                    </ul>
                    {/* Company Logo */}
                    <div className="mt-auto p-4 flex justify-center z-0">
                        <img 
                            src="assets/logo.png" 
                            alt="Company Logo" 
                            className="w-32 h-32 object-contain relative z-[-1]" 
                        />
                    </div>
                </nav>
            </div>
        </div>
    );
};

export default Sidebar;
